import { EnvConfig } from "./envconfig";

export const environment: EnvConfig = {
  production: true,
  sbdBaseUrl: 'https://staging.suretybondsdirect.com',
  apiBaseUrl: 'https://account-portal-api-staging.suretybondsdirect.com',
  dougV2BaseUrl: 'https://staging.suretybondsdirect.com',
  apiLocalUrl: 'https://account-portal-api-staging.suretybondsdirect.com/v4',
  titleBase: '(STG) Surety Bonds Direct Account',
  shouldCathyBeChatty: true,
  reCaptchaSiteKey: '6Lf1vxYqAAAAABdGAN_tEUhdlYlLwAWKUpREW84x',
  apiPrefix: 'v4'
};
